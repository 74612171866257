<template>
	<div class="autocomplite w-full" v-click-outside="close">
		<textarea
			type="text"
			:value="getSearch"
			style="resize: none"
			:rows="getSearch ? 'auto' : 1"
			class="serch-input"
			placeholder="Пожалуйста, выберите МО"
			@click="openMenuList = true"
			@input="updateItems($event)"
		/>
		<span class="arrow">
			<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="#9193A5" stroke-width="2" stroke-linecap="round" />
			</svg>
		</span>
		<ul v-show="openMenuList && getMoArr.length" class="cursor-pointer list w-full">
			<li v-for="(item, key) in getMoArr" class="flex hover:bg-blue-300 p-5" :key="key" @click="addMO(item)">
				<span>{{ item.shortName }}</span>
			</li>
		</ul>
		<p v-if="moError" class="error-text">{{ moError }}</p>
	</div>
</template>

<script>
import { getters, actions, mutations } from '@/store/store';
import ClickOutside from 'vue-click-outside';

export default {
	data() {
		return {
			openMenuList: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		...mutations,
		close() {
			this.openMenuList = false;
		},
		addMO(item) {
			this.setSearch(item.name);
			let medOrgId = item.oldId ? item.oldId : item.id;
			this.getMObyId(medOrgId);
			this.close();
			this.setMoError(null);
		},
		updateItems(e) {
			let query = {
				text: e.target.value,
				id: this.currentRegion.id,
				idSearch: this.getSearchRegionId
			};
			this.setSearch(e.target.value);
			this.getMOList(query);
		}
	},
	directives: {
		ClickOutside
	}
};
</script>

<style>
.autocomplite {
	@apply relative;
	/* width: 654px; */
	max-width: 100%;
}

.arrow {
	@apply absolute right-5 top-6;
}

.serch-input {
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	padding: 16px 50px 16px 20px;
	border: 1px solid #a8b1ce;
	border-radius: 10px;
	color: #000;
	width: 100%;
}

.serch-input:focus {
	outline: 0;
}

.serch-input:focus + .arrow {
	transform: rotate(180deg);
}

.list {
	@apply absolute bg-white shadow-default rounded-b-lg;
	top: 53px;
	height: 250px;
	overflow: auto;
	z-index: 999;
	/* padding: 20px; */
}

.list li {
	padding-bottom: 10px;
}

@media (max-width: 600px) {
	.autocomplite {
		width: 100%;
	}
}
</style>
