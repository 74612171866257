<template>
	<div class="flex flex-row flex-grow">
		<Menu v-on:callMethodInParent="reset()"></Menu>
		<div class="flex flex-col flex-grow max-w-full w-11/12">
			<main class="px-4 py-6 main sm:p-14">
				<div class="box mb-6 sm:hidden" v-if="dataMO">
					<h2 class="text-center text-dark mb-6 text-xl font-bold" v-if="getTitle">{{ getTitle }}</h2>

					<reCapcha v-if="!capcha" />
					<div class="steps" v-else>
						<SelectType v-on:callMethodInParent="reset()" />
						<ChangeRegion v-if="viewAccess" />
						<div class="step-1" v-else-if="dataMO && $route.params.type !== 'donor' && $route.params.type !== 'skoraya'">
							<div class="label text-textGray"><p>Регион</p></div>
							<div class="value">
								<p v-if="currentRegion !== null">{{ currentRegion.name }}</p>
							</div>
							<div class="label text-textGray">
								<p>
									{{
										currentRegion !== null && currentRegion.fedDistrictCode !== null && currentRegion.wikiRegionCode === null
											? 'Медицинская организация, учредителем которой является Российская Федерация'
											: 'Медицинская организация'
									}}
								</p>
							</div>
							<h3 class="text-lg font-bold mb-6 text-dark">{{ dataMO.shortName }}</h3>
							<div class="label text-textGray" v-if="dataMO.departmentBelongName">
								<p>Функции и полномочия учредителя от имени Российской Федерации осуществляет:</p>
							</div>
							<div class="value">
								<p>{{ dataMO.departmentBelongName }}</p>
							</div>
							<div class="label text-textGray" v-if="dataMO.webSiteAddress"><p>Официальный сайт</p></div>
							<div class="value">
								<a class="text-black" :href="`${dataMO.webSiteAddress}`">
									{{ dataMO.webSiteAddress }}
								</a>
							</div>
							<div class="label text-textGray" v-if="dataMO.postAddress"><p>Фактический адрес</p></div>
							<div class="value" v-if="dataMO.postAddress">
								<p>{{ dataMO.postAddress }}</p>
							</div>
						</div>
						<div class="step-1" v-else-if="$route.params.type === 'donor' || $route.params.type === 'skoraya'">
							<div class="label text-textGray"><p>Регион</p></div>
							<div class="value">
								<p v-if="currentRegion !== null">{{ currentRegion.name }}</p>
							</div>
						</div>
						<!-- <div class="step-2">
							<h3 class="text-lg font-bold mb-6 text-dark">Подтвердите, что вы не робот</h3>
							<div class="capcha"></div>
						</div>
						<div class="step-3">
							<h3 class="text-lg font-bold mb-6 text-dark">Найдите медицинских работников</h3>
							<img src="@/assets/img/ask.png" alt="" class="mb-5" />
							<div class="error text-red-500">Не верно, попробуйте еще раз!</div>
						</div> -->
						<div class="step-4" v-for="(question, index) in survey" :key="index + copyText" v-show="index == questionNumber">
							<div class="text-gray-200 font-semibold mb-2">Вопрос {{ index + 1 }} / {{ survey.length }}</div>
							<div class="title flex font-bold mb-2">
								<!-- <span class="text-gray-200 text-base mr-2">1.</span> -->
								<h3 class="text-base text-black font-semibold">{{ question.comment }}</h3>
							</div>
							<div class="ask pl-8">
								<div class="radio mb-6" v-for="answer in question.answers" :key="answer.code">
									<div v-if="answer.title == 'Да/Нет'" class="flex">
										<input
											:id="`input-mob-${answer.code}-1`"
											v-model="answersQuery[answer.code]"
											@change="setAnswer(answer)"
											type="radio"
											:name="`group-mob-${question.id}`"
											:value="true"
										/>
										<label class="radio-label" :for="`input-mob-${answer.code}-1`"><p>Да</p></label>
										<input
											:id="`input-mob-${answer.code}-2`"
											v-model="answersQuery[answer.code]"
											@change="setAnswer(answer)"
											type="radio"
											:name="`group-mob-${question.id}`"
											:value="false"
										/>
										<label class="radio-label" :for="`input-mob-${answer.code}-2`"><p>Нет</p></label>
									</div>
									<div v-else>
										<div class="checkbox-btn" v-if="answer.type === 4">
											<input
												:id="`input-mob-${answer.code}`"
												v-model="answersQuery[answer.code]"
												@change="setAnswer(answer)"
												type="checkbox"
												:name="`group-mob-${question.id}`"
												:value="true"
											/>
											<label class="radio-label" :for="`input-mob-${answer.code}`"
												><p>{{ answer.title }}</p></label
											>
										</div>
										<div class="radio-btn" v-if="answer.type === 3">
											<input
												:id="`input-mob-${answer.code}`"
												v-model="answersQuery[answer.code]"
												type="radio"
												@change="setAnswer(answer)"
												:name="`group-mob-${question.id}`"
												:value="true"
											/>
											<label class="radio-label" :for="`input-mob-${answer.code}`"
												><p>{{ answer.title }}</p></label
											>
										</div>
										<div v-if="answer.type === 1">
											<label class="text-label" :for="`input-mob-${answer.code}`" @click.prevent=""
												><p>{{ answer.title }}</p></label
											>
											<textarea class="textarea" :id="`input-mob-${answer.code}`" v-model="answersQuery[answer.code]" @change="setAnswer(answer)"></textarea>
										</div>
									</div>
								</div>
							</div>
							<div class="buttons flex sm:hidden">
								<button
									@click="nextAnswerButton(index - 1)"
									v-if="index"
									class="btn text-center bg-white border-2 border-dopBlue py-5 text-dopBlue text-sm w-5/12 rounded-xl font-bold mr-4"
								>
									Назад
								</button>
								<button
									@click="nextAnswerButton(index + 1)"
									v-if="index + 1 !== survey.length"
									:disabled="!(disabled && accept)"
									class="btn text-center py-5 text-sm w-full rounded-xl font-bold"
									:class="!(disabled && accept) ? 'bg-gray-100 text-gray-200' : 'bg-dopBlue text-white'"
								>
									Далее
								</button>
								<button
									v-else
									@click="sendAnket()"
									:disabled="!(disabled && accept) || sending"
									class="btn text-center py-5 text-sm w-full rounded-xl font-bold"
									:class="(!(disabled && accept) || sending) ? 'bg-gray-100 text-gray-200' : 'bg-dopBlue text-white'"
								>
									Отправить
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="hidden sm:block">
					<h2 class="text-center text-dark mb-12 text-2xl font-bold" v-if="getTitle">{{ getTitle }}</h2>

					<reCapcha v-if="!capcha" />
					<div class="step" v-else>
						<ChangeRegion v-if="viewAccess" />
						<div v-else-if="dataMO && $route.params.type !== 'donor' && $route.params.type !== 'skoraya'">
							<div class="mb-6" v-if="currentRegion !== null">
								<p class="label-lg">Регион</p>
								<p class="value-lg">{{ currentRegion.name }}</p>
							</div>
							<p class="label-lg">
								{{
									currentRegion !== null && currentRegion.fedDistrictCode !== null && currentRegion.wikiRegionCode === null
										? 'Медицинская организация, учредителем которой является Российская Федерация'
										: 'Медицинская организация'
								}}
							</p>
							<h3 class="text-lg font-bold mb-6 text-dark">{{ dataMO.shortName }}</h3>
							<div class="mb-6">
								<p class="label-lg" v-if="dataMO.departmentBelongName">Функции и полномочия учредителя от имени Российской Федерации осуществляет:</p>
								<p class="value-lg">{{ dataMO.departmentBelongName }}</p>
							</div>
							<div class="mb-6" v-if="dataMO.webSiteAddress">
								<p class="label-lg">Официальный сайт</p>
								<p class="value-lg">{{ dataMO.webSiteAddress }}</p>
							</div>
							<p class="label-lg" v-if="dataMO.postAddress">Фактический адрес</p>
							<p class="value-lg" v-if="dataMO.postAddress">{{ dataMO.postAddress }}</p>
						</div>
						<div v-else-if="$route.params.type === 'donor' || $route.params.type === 'skoraya'">
							<div class="mb-6" v-if="currentRegion !== null">
								<p class="label-lg">Регион</p>
								<p class="value-lg">{{ currentRegion.name }}</p>
							</div>
						</div>
						<div class="box mt-11" v-for="(anketa, index) in survey" :key="index + copyText" v-show="index == questionNumber">
							<div class="text-gray-300 font-semibold mb-2">Вопрос {{ index + 1 }} / {{ survey.length }}</div>
							<div class="flex answer-box">
								<div class="title flex font-bold mb-2 w-6/12">
									<!-- <span class="text-gray-300 text-base mr-2">{{index + 1}}.</span> -->
									<h3 class="text-base text-black font-semibold">{{ $route.params.type !== 'anketa' ? anketa.comment : anketa.title }}</h3>
								</div>
								<div class="ask pl-8 w-6/12" :key="anketa.id">
									<div class="radio mb-6" v-for="answer in anketa.answers" :key="answer.code">
										<div v-if="answer.title == 'Да/Нет'" class="flex">
											<input
												:id="`input-${answer.code}-1`"
												v-model="answersQuery[answer.code]"
												@change="setAnswer(answer)"
												type="radio"
												:name="`group-${anketa.id}`"
												:value="true"
											/>
											<label class="radio-label mr-4" :for="`input-${answer.code}-1`">Да</label>
											<input
												:id="`input-${answer.code}-2`"
												v-model="answersQuery[answer.code]"
												@change="setAnswer(answer)"
												type="radio"
												:name="`group-${anketa.id}`"
												:value="false"
											/>
											<label class="radio-label" :for="`input-${answer.code}-2`">Нет</label>
										</div>
										<div v-else>
											<div class="checkbox-btn" v-if="answer.type === 4">
												<input
													:id="`input-${answer.code}`"
													v-model="answersQuery[answer.code]"
													@change="setAnswer(answer)"
													type="checkbox"
													:name="`group-${anketa.id}`"
													:value="true"
												/>
												<label class="radio-label" :for="`input-${answer.code}`"
													><p>{{ answer.title }}</p></label
												>
											</div>
											<div class="radio-btn" v-if="answer.type === 3">
												<input
													:id="`input-${answer.code}`"
													v-model="answersQuery[answer.code]"
													@change="setAnswer(answer)"
													type="radio"
													:name="`group-${anketa.id}`"
													:value="true"
												/>
												<label class="radio-label" :for="`input-${answer.code}`"
													><p>{{ answer.title }}</p></label
												>
											</div>
											<div v-if="answer.type === 1">
												<label class="text-label" :for="`input-${answer.code}`" @click.prevent=""
													><p>{{ answer.title }}</p></label
												>
												<textarea class="textarea" :id="`input-${answer.code}`" v-model="answersQuery[answer.code]" @change="setAnswer(answer)"></textarea>
											</div>
										</div>
									</div>
									<div class="flex">
										<button
											@click="nextAnswerButton(index - 1)"
											v-if="index"
											class="btn text-center bg-white border-2 border-dopBlue py-3.5 text-dopBlue text-base w-5/12 rounded-xl font-bold mr-4"
										>
											Назад
										</button>
										<button
											@click="nextAnswerButton(index + 1)"
											:disabled="!accept"
											v-if="index + 1 !== survey.length"
											class="btn text-center py-3.5 w-8/12 rounded-xl text-base font-bold"
											:class="!accept ? 'bg-gray-100 text-gray-200' : 'bg-dopBlue text-white'"
										>
											Далее
										</button>
										<button
											v-else
											@click="sendAnket()"
											:disabled="!accept || sending"
											class="btn text-center py-3.5 w-8/12 rounded-xl text-base font-bold"
											:class="!accept || sending ? 'bg-gray-100 text-gray-200' : 'bg-dopBlue text-white'"
										>
											Отправить
										</button>
									</div>
									<p class="error" v-show="error">{{ disabled ? 'Выберите медицинскую организацию' : 'Выберите ответ' }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fixed popup-wrapp flex inset-0" v-show="popup">
					<div class="popup">
						<div class="popup-header">
							<h3>Результат</h3>
							<span @click="close()">
								<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M6 6L20.5 20.5" stroke="#3377FF" stroke-width="3" />
									<path d="M20.5 6L6 20.5" stroke="#3377FF" stroke-width="3" />
								</svg>
							</span>
						</div>
						<div class="popup-content">
							<h2>Анкета успешно принята системой</h2>
							<div class="popup-item sm:flex">
								<p class="popup-label">
									Количество анкет: <span>{{ anketsCount }}</span>
								</p>
								<!-- <button class="btn-outline w-full sm:w-2/5 rounded-xl">Проверить</button> -->
							</div>
							<div class="popup-item">
								<p class="popup-label mb-3">Уникальный код акеты:</p>
								<div class="sm:flex w-full">
									<input type="text" class="popup-input" v-model="copyText" readonly />
									<button class="btn-outline rounded-xl w-full sm:w-2/5 sm:rounded-l-none" @click="copyAnketId()">Копировать</button>
								</div>
								<p class="popup-label mt-3" v-if="copy">Успешно скопировано в буфер обмена</p>
							</div>
							<button
								@click="close()"
								class="btn text-center bg-dopBlue border-2 py-3.5 text-white w-full sm:w-32 rounded-xl text-base font-bold hover:text-blue-500 hover:bg-white border-2 border-dopBlue"
							>
								ОК
							</button>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import ChangeRegion from '@/components/ChangeRegion.vue';
import SelectType from '@/components/SelectType.vue';
import reCapcha from '@/components/reCapcha.vue';
import {
	getters, actions, mutations, methods
} from '@/store/store';

export default {
	name: 'Questionnaire',
	components: {
		ChangeRegion,
		reCapcha,
		SelectType,
		Menu
	},
	data() {
		return {
			copy: false,
			popup: false,
			accept: false,
			error: false,
			anketsCount: 0,
			copyText: '',
			answersQuery: {
				anketaId: '',
				medicalId: '',
				isPaper: false,
				ipAddress: ''
			},
			routeRegion: true
		};
	},
	computed: {
		...getters,
		viewAccess() {
			let n = this.$route.name,
				t = this.$route.params.type;

			return (n === 'QuestionnaireRegion' || n === 'QuestionnaireRegion2') && t !== 'donor' && t !== 'skoraya';
		}
	},
	methods: {
		...actions,
		...mutations,
		...methods,
		reset() {
			this.accept = false;

			this.answersQuery = {
				anketaId: '',
				medicalId: '',
				isPaper: false,
				ipAddress: ''
			};

			this.resetSurvey();
		},
		close() {
			this.popup = false;
			this.reset();
		},
		postTrack() {
			this.$http.post('/Anketa/PostTrack', {
				regionId: this.currentRegion.id,
				orgId: this.dataMO.id,
				anketaId: this.anketRoutType,
				questionId: this.survey[this.questionNumber].id
			});
		},
		nextAnswerButton(page) {
			if (this.questionNumber < page) {
				this.postTrack();

				this.accept = this.checkIfQuestionIsOptional(page);
				this.setDefaultAnswer(page);
			} else {
				let currentAnswer = this.getAnswerToQuestion(this.questionNumber);

				if (currentAnswer !== null) {
					this.adjustSurvey(currentAnswer, this.answersQuery[currentAnswer.code], false);
				}

				this.deleteAnswersToQuestion(this.questionNumber);

				let previousAnswer = this.getAnswerToQuestion(page);
				this.accept = previousAnswer !== null;
			}

			this.nextAnswer(page);
		},
		checkIfQuestionIsOptional(number) {
			let answerOptions = this.survey[number].answers;

			if (answerOptions.length === 1 && answerOptions[0].type === 1) {
				return true;
			}

			return false;
		},
		setDefaultAnswer(number) {
			let answerOptions = this.survey[number].answers;

			answerOptions.forEach((x) => {
				if (x.type === 4) {
					this.answersQuery[x.code] = false;
				}
			});
		},
		deleteAnswersToQuestion(number) {
			let answerOptions = this.survey[number].answers;

			for (let i = 0; i < answerOptions.length; i += 1) {
				delete this.answersQuery[answerOptions[i].code];
			}
		},
		getAnswerToQuestion(number) {
			let answerOptions = this.survey[number].answers;

			for (let i = 0; i < answerOptions.length; i += 1) {
				if (Object.prototype.hasOwnProperty.call(this.answersQuery, answerOptions[i].code)) {
					if (answerOptions[i].type === 3 && answerOptions.length > 1) {
						if (this.answersQuery[answerOptions[i].code] === true) {
							return answerOptions[i];
						}
					} else if (answerOptions[i].type === 4) {
						if (this.answersQuery[answerOptions[i].code] === true) {
							return answerOptions[i];
						}
					} else if (this.answersQuery[answerOptions[i].code] !== null) {
						return answerOptions[i];
					}
				}
			}

			return null;
		},
		copyAnketId() {
			navigator.clipboard.writeText(this.copyText);
			this.copy = true;
		},
		setAnswer(answer) {
			let answerOptions = this.survey[this.questionNumber].answers;

			if (answer.type === 3) {
				if (answerOptions.length === 1) {
					this.adjustSurvey(answerOptions[0], !this.answersQuery[answerOptions[0].code], false);
				} else {
					answerOptions.forEach((x) => {
						if (x.code === answer.code) {
							this.answersQuery[x.code] = true;
						} else {
							if (this.answersQuery[x.code]) {
								this.adjustSurvey(x, this.answersQuery[x.code], false);
							}

							this.answersQuery[x.code] = false;
						}
					});
				}
			}

			let answerResult = this.getAnswerToQuestion(this.questionNumber);
			this.accept = answerResult !== null;

			this.adjustSurvey(answer, this.answersQuery[answer.code], true);
		},
		sendAnket() {
			if (this.sending) {
				return;
			}

			if (this.viewAccess && !this.dataMO.id && !this.dataMO.oldId) {
				this.setMoError('Пожалуйста, заполните поле "Медицинская организация"');
				return;
			}

			this.setSending(true);

			this.postTrack();

			let anketa = {
				type: this.calcType(),
				resulte: this.answersQuery,
				region: this.currentRegion
			};
			this.answersQuery.medicalId = this.dataMO.id;
			this.answersQuery.medicalOldId = this.dataMO.oldId;
			this.answersQuery.anketaId = this.anketRoutType;
			this.postAnket(anketa).then((res) => {
				this.setSending(false);
				this.copy = false;
				this.popup = true;
				this.copyText = res.data.anketCode;
				this.anketsCount = res.data.anketsCount;
			});
		},
		saveOne(anketaQuestionId, anketaAnswerId, value, medicalId, anketaId, anketaResultId) {
			this.$http.post('/Anketa/PostAnswer', {
				anketaQuestionId,
				anketaAnswerId,
				value,
				medicalId,
				anketaId,
				anketaResultId
			});
		},
		saveBtn() {
			this.survey.forEach((item) => {
				item.forEach((it) => {
					if (it.value) {
						it.answers.forEach((answer) => {
							this.saveOne(answer.anketaQuestionId, answer.id, it.value, this.$route.params.anketaResultId);
						});
					}
				});
			});

			this.showNotification('Ответы успешно сохранены!', 'success');
		},
		calcType() {
			let typeName = this.$route.params.type;
			if (typeName === '121' || typeName === 121) {
				typeName = 'ambulator';
			}
			if (typeName === '125' || typeName === 125) {
				typeName = 'stacionar';
			}
			if (typeName === '114' || typeName === 114) {
				typeName = 'healthResort';
			}
			if (typeName === '113' || typeName === 113) {
				typeName = 'psychiatric';
			}
			if (typeName === '112' || typeName === 112) {
				typeName = 'donor';
			}
			if (typeName === '116' || typeName === 116) {
				typeName = 'skoraya';
			}
			if (!typeName) {
				typeName = 'ambulator';
			}
			return typeName;
		}
	},
	mounted() {
		let typeName = this.calcType();
		let regionId = this.$route.params.id;
		if (this.$route.name === 'Questionnaire3') {
			// nok.minzdrav.gov.ru/site.html#!/8/643#reviews
			let a = this.$route.hash;
			if (a.lastIndexOf('#') > 0) {
				// '#' may be escaped as '%23'
				a = a.substr(3, a.length - 11).split('/');
			} else {
				a = a.substr(3, a.length - 13).split('/');
			}
			regionId = +a[0];
			this.$route.params.idorg = +a[1];
		}
		if (typeName !== 'anketa') {
			this.routeRegion = this.$route.name === 'QuestionnaireRegion' || this.$route.name === 'QuestionnaireRegion2';
			this.changeType(typeName);

			let setupRegionView = (regId) => {
				let gotRegion = this.getRegionById(regId);
				let query = {
					text: '',
					id: regId
				};
				if (regId.length < 10) {
					gotRegion.then((resr) => {
						query.id = resr.data.id;
						this.getMOList(query);
					});
				} else {
					this.getMOList(query);
				}
				this.getAnketaById().then(() => {
					this.setDefaultAnswer(this.questionNumber);
				});
				this.getRegions('');
			};

			if (!this.routeRegion) {
				this.getMObyId(this.$route.params.idorg).then((res) => {
					if (res.data) {
						if (!res.data[typeName]) {
							if (res.data.stacionar) {
								typeName = 'stacionar';
							} else if (res.data.ambulator) {
								typeName = 'ambulator';
							} else if (res.data.healthResort) {
								typeName = 'healthResort';
							} else {
								typeName = 'psychiatric';
							}
							if (!regionId) {
								if (res.data.regionOldId) regionId = res.data.regionOldId;
								else if (res.data.regionId) regionId = res.data.regionId;
								else regionId = 0;
							}
							this.$router.push({ name: 'Questionnaire', params: { type: typeName, id: regionId, idorg: this.$route.params.idorg } });
							this.changeType(typeName);
						}
						if (!regionId || regionId === '0' || regionId.length > 5) {
							if (res.data.regionOldId) regionId = res.data.regionOldId;
							else if (res.data.regionId) regionId = res.data.regionId;
						}
						this.getRegionById(regionId);
						this.getAnketaById().then(() => {
							this.setDefaultAnswer(this.questionNumber);
						});

						if (res.data.outdated) {
							this.$router.push({ name: 'QuestionnaireRegion', params: { type: typeName, id: regionId } });
							this.setMO(false, {});
							setupRegionView(regionId);
						}
					} else {
						this.$router.push({ name: 'QuestionnaireRegion', params: { type: typeName, id: regionId } });

						setupRegionView(regionId);
					}
				});
				this.changeStateMo(true);
			} else {
				/* let gotRegion = this.getRegionById(regionId);
				let query = {
					text: '',
					id: regionId
				};
				if (regionId.length < 10) {
					gotRegion.then((res) => {
						query.id = res.data.id;
						this.getMOList(query);
					});
				} else {
					this.getMOList(query);
				}
				this.getAnketaById().then(() => {
					this.setDefaultAnswer(this.questionNumber);
				}); */

				setupRegionView(regionId);
				if (this.$route.params.type !== 'donor' && this.$route.params.type !== 'skoraya') {
					this.changeStateMo(false);
				} else {
					this.changeStateMo(true);
				}
				// this.getRegions('');
			}
		}
	}
};
</script>

<style scoped>
.popup-wrapp {
	background: rgba(106, 110, 131, 0.5);
	z-index: 99999;
}

.popup {
	@apply rounded-xl bg-white shadow-default m-auto overflow-hidden;
	width: 650px;
	max-width: 90%;
}

.special .popup {
	width: 90%;
}

.popup-header {
	@apply py-6 px-8 bg-gray-100 flex justify-between items-center;
}

.popup-header h3 {
	@apply text-lg font-extrabold;
}

.popup-label {
	@apply text-textGray text-sm font-bold mb-5 sm:mb-0;
}

.popup-label span {
	@apply text-base font-bold text-black;
}

.popup-content {
	@apply p-4 sm:p-8 sm:pl-10;
}

.popup-content h2 {
	@apply text-xl sm:text-3xl font-extrabold mb-12;
}

.popup-item {
	@apply justify-between items-center mb-10;
}

.popup-input {
	@apply w-full py-3 px-6 border border-gray-100 rounded-lg sm:rounded-r-none sm:border-r-0 mb-2 sm:mb-0;
}

.popup-input:focus {
	outline: 0;
}

.btn-outline {
	width: 168px;
	@apply text-base font-extrabold py-3 text-blue-500;
	min-width: 168px;
	border: 2px solid #3377ff;
}

.special .btn-outline {
	width: 30%;
}

.btn-outline:hover {
	@apply text-white bg-blue-500;
}

.label-lg {
	@apply text-sm pb-2.5 font-bold;
	color: #a8b1ce;
}

.textarea {
	@apply w-full border resize-none rounded-xl h-20 mt-2.5 p-2;
}

.textarea:focus {
	outline: 0 !important;
}

.main {
	max-width: 1125px;
}

.box {
	@apply py-6 px-4 shadow-default rounded-xl sm:px-12 sm:py-6 bg-white sm:mb-6;
}

.label {
	@apply text-sm font-bold mb-3.5;
}

.value {
	@apply text-base mb-8 text-black font-medium;
}

.radio input {
	display: none;
}

.radio .radio-label {
	@apply leading-6 cursor-pointer;
	display: flex;
	position: relative;
	width: 100%;
}

.text-label {
	@apply leading-6;
}

.radio .radio-label::before {
	content: '';
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 14px;
	display: block;
	border-radius: 50%;
	margin-top: 3px;
	border: 1px solid #a8b1ce;
}

.radio input:checked + .radio-label:after {
	content: '';
	display: block;
	position: absolute;
	left: 3px;
	top: 6px;
	border-radius: 50%;
	background: #3377ff;
	width: 14px;
	height: 14px;
}

.special .answer-box {
	flex-direction: column;
}

.special .title {
	width: 100%;
	margin-bottom: 40px;
}

.special .ask {
	width: 100%;
}

.radio .checkbox-btn input:checked + .radio-label:after {
	border-radius: 0;
}

.radio .checkbox-btn .radio-label::before {
	border-radius: 0;
}

.value-lg {
	@apply font-medium text-base;
}

.special .btn {
	border: 1px solid #000;
}

@media (max-width: 600px) {
	.buttons {
		@apply fixed bottom-2 left-2 right-2;
	}

	.btn {
		@apply w-full;
	}
}
</style>
